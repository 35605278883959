export const white = '#fff'
export const black = '#000'
export const fadedBlack = 'rgba(0, 0, 0, 0.65)'
export const fadedBlack1 = '#4b4b4b'
export const fadedBlack2 = '#474747'
export const lightFadedBlack = 'rgba(0, 0, 0, 0.15)'
export const textBlackColor = '#212E3A'
export const lightBlackBoldText = '#454B5B'

export const themeColor = '#1AB394' // green
export const themeColorLight = '#42d184' // light green
export const themeColorLight1 = '#3DD29F' // light green
export const themeColorLighter = '#5EB500' // light green
export const themeColorLighter1 = '#E8F7F4'
export const themeColorSecondaryLighter = '#26B96A' // light green
export const themeColorTagsBg = '#D1F9EB'
export const themeColorBreadcrumb = '#69727E'
export const themeLightGrayColor = '#BBBFC4'
export const themeLightGrayBgColor = '#F1F1F1'
export const themeColorBlue = 'rgba(63, 132, 229, 1)'
export const themeColorHoverBlue = '#1A73E8'
export const drcThemeColor = '#3f85e5'
export const drcWhite = '#fff'
export const parcThemeColor = '#666666'
export const parcButtonColor = '#eee'

export const greyThemeDark1 = '#434B5D'
export const greyThemeDark2 = '#878A91'
export const greyThemeDark3 = '#69727e'
export const greyThemeDark4 = '#2F4151'
export const greyThemeDark5 = '#8D929D'
export const greyThemeDark6 = '#97A4C1'
export const greyThemeDark7 = '#ADADAD'
export const greyThemeLight = '#b9b9b9'
export const greyLight1 = '#e8e8e8'
export const greyThemeLighter = '#f8f8f8'
export const brownDark = '#964B00'
export const brownDark1 = '#595959'

export const grey = '#dfdfdf'
export const fadedGrey = '#e5e5e5'
export const fadedGrey1 = '#e4e4e4'
export const fadedGrey2 = '#EBEBEB'
export const lightGreySecondary = '#f8f8f8'
export const lightGrey = '#f9f9f9'
export const lightGrey1 = '#f6f6f6'
export const lightGrey2 = '#888787'
export const lightGrey3 = '#f4f3f3'
export const lightGrey4 = '#dbdce0'
export const lightGrey5 = '#8E9AA4'
export const lightGrey6 = '#848993'
export const lightGrey7 = '#949ca4'
export const lightGrey8 = '#dbdbdb'
export const lightGrey9 = '#6a737f'
export const lightGrey10 = '#a6a7aa'
export const lightGrey11 = '#aaafb5'
export const lightGrey12 = '#858585'
export const lightGrey13 = '#607380'
export const lightGrey14 = '#738294'
export const lightGrey15 = '#d4d4d4'
export const lightGrey16 = '#dddddd'
/** Light Grey for modern redesign */
export const lightGrey17 = '#707070'
export const lightGrey18 = '#BABABA'
export const lightGrey19 = '#aeb2b7'
export const lightGrey20 = '#F0F0F0'
export const darkGrey = '#a3a0a0'
export const darkGrey1 = '#b5b5b5'
export const darkGrey2 = '#304050'
export const darkGrey3 = '#505050'
export const darkGrey4 = '#414141'
export const darkGrey5 = '#8E8E8E'
export const darkGrey6 = '#767676'
export const textColor = lightGrey6
export const greyish = '#f3f3f4'
export const greyishDarker1 = '#a7b1c2'
export const greyishDarker2 = '#cccccc'
export const mainBgColor = '#ffffff'
export const greyishBorder = '#B6B6CC'
export const mainTextColor = '#444444'
export const secondaryTextColor = '#434b5d'
export const greyDarken = '#979BA1'
export const tagTextColor = '#676e74'
export const tagsBgColor = '#b3bcc4'
export const titleColor = darkGrey2
export const greyScoreCardTitleColor = lightGrey11
export const cardTitleColor = lightGrey11
export const linkColor = greyThemeDark3
export const linkColor1 = lightGrey9
export const someGreyColor1 = '#c0c0c0'
export const labelGrey = '#9B9B9B'
export const labelGrey2 = '#a2a2a2'
export const borderGrey = '#E1E1E1'
export const placeholderGray = '#b1b1b1'
export const backgroundGrey = '#F2F2F2'
export const borderGrey2 = '#cbcbcb'
export const borderGrey3 = '#e9e9e9'
export const borderGrey4 = '#dadae4'
export const borderGrey5 = borderGrey
export const backgroundGrey2 = '#FBFBFB'

export const whiteSmoke = '#f5f5f5'

export const separatorColor = '#d6d0d0'
export const dashBorderColor = '#e6e6e6'
export const dashBorderColor1 = '#D0D0D0'
export const dashBorderColorOpacity = '#e6e6e633'
export const dropZoneTitleColor = '#b1b1b1'
export const skippedBarColor = '#6a737f'
export const premiumBg = '#FEB63A'

export const svgMapFillColor = 'rgba(0, 176, 255, 0.19)'
export const svgMapStrokeColor = 'rgba(0, 176, 255, 1)'
export const svgDisabledColor = '#80838b'

export const red = '#FF0057'
export const lightRed = '#fbdfe7'
export const lightRed2 = '#f58888'
export const lightRed3 = '#ec0149'
export const ligthRed4 = '#F7CCCB'
export const lightRed5 = '#E55C5C'
export const lightRed6 = '#EFBBBB'
export const lightRed7 = '#EF767A'
export const lightRed8 = '#FD9F8D'
export const lightRed9 = '#FDEFF2'
export const darkRed = '#F35F5F'
export const darkRed1 = '#F25782'
export const darkRed2 = '#EB4032'
export const darkRed3 = '#E7BB7F'
export const redDark = '#dd2e44'
export const darkRed4 = '#EF767A'
export const darkRed5 = '#EB345B'
export const fadedRed = '#fce0e8'
export const deleteRed = '#FF0080'
export const deleteRed2 = '#EF3863'
export const strikeOutHover = '#f7cbcc'
export const fieldRequiredColor = '#e82a83'
export const fieldRequiredColor2 = '#ff0100'

export const mainBlueColor = '#00b0ff'
export const blue = '#1774F0'
export const blue1 = '#1f72bf'
export const lightBlue = '#93d8f7'
export const lightBlue1 = '#82CAFA'
export const lightBlue2 = '#e6f7ff'
export const lightBlue3 = '#3793dc'
export const lightBlue4 = '#40a1ee'
export const lightBlue5 = '#91d5dc'
export const lightBlue6 = '#c8e8f6'
export const lightBlue7 = '#4e95f3'
export const lightBlue8 = '#5faef3'
export const lightBlue9 = '#00b2ff'
export const lightBlue10 = '#3f85e5'
export const lightBlue11 = '#97a4c1'
export const darkBlue = '#0080b9'
export const darkBlue1 = '#157ad8'
export const darkBlue2 = '#3896be'
export const darkBlueSecondary = '#0288d1'
export const blueButton = '#0e93dc'
export const blueBorder = '#40a9ff'
export const alertColor = '#faad14'
export const dangerColor = '#f5222d'
export const fadeBlue = '#9bc5ff80'

export const green = '#1AB394'
export const lightGreen = '#f5f9fe'
export const lightGreen1 = '#62ebbd'
export const lightGreen2 = '#94df5e'
export const lightGreen3 = '#d2fca5'
export const lightGreen4 = '#e1fbed'
export const lightGreen5 = '#00ad50'
export const lightGreen6 = '#d7faee'
export const lightGreen7 = '#6ec88c'
export const lightGreen8 = '#74E27A'
export const lightGreen9 = '#3dd29f'
export const lightGreen10 = '#1ab395'
export const lightGreen11 = '#53B095'
export const lightGreen12 = '#5FAD5A'
export const lightGreen13 = '#CEF5D8'
export const lightGreen14 = '#8ACE94'
export const lightGreen15 = '#A0DDD1'
export const lightGreen16 = '#57BD90'
export const lightGreen17 = '#F4F9EF'
export const greenDark = '#4aac8b'
export const greenDark1 = '#00b373'
export const greenDark2 = '#1fb58b'
export const greenDark3 = '#27947a'
export const greenDark4 = '#5eb400'
export const greenDark5 = '#00803B'
export const greenDark6 = '#11987D'
export const greenDark7 = '#64C4AE'
export const greenDark8 = '#74B335'
export const greenDark9 = '#578F1E'
export const greenDarkSecondary = '#057750'
export const greenSecondary = '#4CD43B'
export const fadedGreen = '#c7ead9'
export const fadedGreen1 = '#D8EEE2'
export const fadedGreen2 = '#89CE94'

export const greenPrimary = '#0EB08D'
export const greenThird = '#5eb500'

export const greenSomeWhatDark = '#17a67d'

export const yellow = '#febe00'
export const yellow1 = '#FDCC3B'
export const yellow2 = '#ffd500'
export const yellow3 = '#FDCA40'
export const yellow4 = '#FFDF88'
export const yellow5 = '#E49B0F'
export const yellow6 = '#FFF4D6'
export const yellow7 = '#AB7F00'

export const orange = '#FFA200'
export const orangeTheme1 = '#FFE1D6'
export const orangeTheme2 = '#E16D44'
export const darkOrange = '#F9942D'
export const darkOrange1 = '#EB954B'

export const btnBg = '#277df1'
export const btnBgActive = '#5196f3'
export const btnColor = '#bed8fa'

export const title = '#434B5D'
export const darkTitle = '#292f3c'

export const sectionBorder = '#e6e6e6'

export const tabGrey = '#7C848E'

export const inputBgGrey = '#F8F8FB'
export const redHeart = '#e35783'
export const inputBorder = '#d9d9d9'
export const inputBorder1 = '#b9b9b9'
export const selectColor = '#5a6171'
export const dragDropUploadText = '#b2b2b2'
export const dragDropHighlightText = '#1766CE'

export const cardBg = '#f8f8fb'
export const cardBorder = '#e6e6e6'

export const gray300 = '#BBBBBB'
export const gray600 = '#555555'
// shadows
export const boxShadowDefault = '0px 2px 5px 0px rgba(0, 0, 0, 0.7)'

export const boxShadowColor = '#C9D0DB80'
export const boxShadowColor2 = '#0000001A'
export const boxShadowColor3 = '#00000029'
export const boxShadowColor4 = '#51586829'
export const paginationBoxShadowColor = '#a3a0a033'

// Graph colors
export const greyGraphstroke = '#999'
export const barGrapColor1 = '#1fe3a0'
export const barGrapColor2 = '#ee1b82'
export const incorrect = darkRed
export const pCorrect = '#ebaa28'
export const graded = '#7BC0DF'
export const pointColor = '#4a4a4a'
export const notStarted = '#bfbfbf'

// numberPad button color
export const numBtnColors = {
  color: '#707070',
  borderColor: '#D9D9D9',
  backgroundColor: '#fff',
}

// Sizes
export const smallMobileWidth = '325px'
export const middleMobileWidth = '476px'
export const mobileWidth = '480px'
export const mobileWidthLarge = '575px'
export const mobileWidthMax = '767px'
export const tabletWidth = '768px'
export const desktopWidth = '992px'
export const smallDesktopWidth = '1024px'
export const largeDesktopWidth = '1200px'
export const mediumDesktopExactWidth = '1366px'
export const extraDesktopWidth = '1440px'
export const extraDesktopWidthMax = '1600px'

export const testTypeColor = {
  assessment: '#5EB500',
  common: '#FF9100',
  practice: '#00A8FF',
  'common assessment': '#FF9100',
  'school common assessment': '#FF9100',
  homework: '#e82a83',
  quiz: '#873fe5',
  survey: '#8D4F1A',
  adaptive: '#007D65',
}

export const authorAssignment = {
  assignmentStatusBg: {
    NOT_OPEN: '#95B0CD',
    IN_PROGRESS: '#5AABEB',
    IN_GRADING: '#F9942D',
    NOT_GRADED: '#e06666',
    GRADES_HELD: '#00AD50',
    SUBMITTED: '#F9942D',
    NOT_STARTED: '#AAAFB5',
    DONE: '#00AD50',
  },
  lightBlue: '#12a6e8',
}

export const fadedWhite = '#5196f3'
export const fadedWhite2 = '#FFFFFFB2'
export const fadedBlue = '#BED8FA'

export const eastBaycolor = '#51596a'

export const draftColor = '#f8ac59'
export const publishedColor = '#1ab394'
export const publishStatusColor = '#e8f2ff'

export const backgrounds = {
  default: '#f3f3f3',
  primary: '#f8f8f8',
}

export const borders = {
  default: '#f2f2f2',
  primary: '#b1b1b1',
  secondary: '#e6e6e6',
  tag: '#e2e2e2',
}

export const highlightColors = {
  blue: '#99c2ff',
  green: '#99ff99',
  orange: '#ff8533',
  yellow: '#ffff80',
  pink: '#ff99bb',
}

export const publisherFont1 = '#8080a7'
export const publisherFont2 = '#434b5d'
export const publisherFont3 = '#8888a4'
export const publisherCollectionBg = '#f3f3f8'
export const publisherItemBankIcon = '#7e3ec4'
export const publisherTestsIcon = '#13CECF'
export const publisherPlaylistIcon = '#1487DB'
export const publisherBorder1 = '#d4d4e5'
export const filterIconColor = '#2f4151'

export const playlistTabLink = '#989BA4'

export const graphTickColor = '#435b5d'

// evaluation colors
export const correctBgColor = '#e2fcf3'
export const correctIconColor = '#36D29C'
export const wrongBgColor = '#fde0e9'
export const wrongIconColor = '#F25783'
export const skippedBgColor = '#f5f5f5'
export const skippedIconColor = '#a7a7a7'
export const partialBgColor = '#fbfae0'
export const partialIconColor = '#bdb956'

export const lightBrown = '#efe3bb'
export const lightSkyBlue = '#b9d5fa'

export const placeholderPrefixColor = '#999999'
export const placeholderTextColor = '#777777'

// Froala tool button colors
export const froalaToolButtonBackgroundColor = '#0098f7' // sky blue color

export const aiGradientColors = {
  color1: '#007d65',
  color2: '#1766ce',
}

export const vqUsageProgressColor = {
  red: '#FF0000',
  orange: '#EDA222',
  green: '#1AB395',
  trailColor: '#d8d8d8',
}

export const aiLinearGradient = `${aiGradientColors.color1} 0%, ${aiGradientColors.color2} 100%`
