import { getApiUri } from '../../../../src/utils/commons'
import {
  appendRequestConfig,
  appendResponseData,
  appendResponseHeaders,
} from './API'

export default async function KeepAliveAPI({
  baseURL = getApiUri(),
  defaultToken = false,
  method = 'get',
  url,
  ...rest
}) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    keepalive: true,
    ...rest,
  }
  appendRequestConfig(defaultToken)(config)
  const response = await fetch(baseURL + url, {
    method,
    ...config,
  })
  if (response.status >= 200 && response.status < 300) {
    return appendResponseHeaders(response)
  }
  return appendResponseData()
}
